import _ from 'lodash';
var TRANSACTION_START_TYPE = 'requested';
var userTransactions = {
    status: {
        // predefined events
        'fullscreenModal.load': true,
    },
    getEventName: function (_a) {
        var eventName = _a.eventName;
        return eventName.split('.').slice(0, -1).join('.');
    },
    getTransactionType: function (_a) {
        var eventName = _a.eventName;
        return _.last(eventName.split('.'));
    },
    isTransaction: function (_a) {
        var eventName = _a.eventName, previousEventName = _a.previousEventName;
        var isTransactionStartEvent = userTransactions.getTransactionType({ eventName: eventName }) ===
            TRANSACTION_START_TYPE;
        return isTransactionStartEvent || previousEventName;
    },
    hasActive: function (eventName) {
        return Boolean(userTransactions.status[eventName]);
    },
    start: function (eventName) {
        userTransactions.status[eventName] = true;
    },
    complete: function (eventName) {
        return delete userTransactions.status[eventName];
    },
    getTransactionResult: function (event) {
        var eventName = userTransactions.getEventName(event);
        var transactionType = userTransactions.getTransactionType(event);
        if (transactionType === TRANSACTION_START_TYPE) {
            if (!userTransactions.hasActive(eventName)) {
                userTransactions.start(eventName);
            }
            return true;
        }
        if (!userTransactions.hasActive(eventName)) {
            return false;
        }
        return userTransactions.complete(eventName);
    },
};
export default userTransactions;
